import React from "react"
import Layout from "src/component/layout/layout"
import { Seo } from "src/component/common/seo"

export default function Privacy() {
    return (
        <>
            <Layout>
                <div className="p-privacy">
                <div className="p-privacy__inner c-inner-primary">
                    <div className="l-oldContent">
                        <div className="c-title01">
                            <h1 className="p-contact__title__text c-title01__text">プライバシーポリシー</h1>
                        </div>
                        <div className="l-oldContent__inner">
                            <section className="c-section">
                                <div className="l-frame">
                                    <div className="t-privacy-wrap">
                                        <p>株式会社DATAFLUCT（以下「当社」といいます。）は、個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。なお、本プライバシーポリシーにおいて別段の定めがない限り、本プライバシーポリシーにおける用語の定義は、個人情報保護法の定めに従います。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>1. 個人情報の定義</h2>
                                        <p>本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義される個人情報を意味するものとします。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>2. 個人情報の利用目的</h2>
                                        <p>当社は、個人情報を以下の目的で利用いたします。</p>
                                        <ol>
                                            <li>当社が提供する各データサイエンスサービス（CO2排出量の分析、需要予測、ダイナミックプライシング、IoTサービス、商圏分析、売上推定、物件紹介及び事業計画シミュレーションを含みますが、これらに限られません。以下「本サービス」といいます。）の提供のため</li>
                                            <li>ユーザーの個人認証及びユーザー向け本サービスの提供のため</li>
                                            <li>ユーザーの申込み及び承諾に基づく、本サービスを利用する企業等への個人情報の提供のため</li>
                                            <li>新着情報、更新情報に関する通知その他本サービスに関する通知、ご案内、お問い合せ等への対応のため</li>
                                            <li>属性情報、端末情報、位置情報及び行動履歴等に基づく広告やコンテンツ等の配信及び表示のため</li>
                                            <li>当社及び第三者の商品、サービス等のご案内のため（但し、第三者に提供するユーザーの情報の範囲は、予めユーザーが提供することに同意した範囲に限ります。）</li>
                                            <li>ユーザーのサービスの利用の状況等に関する情報を分析して本サービスの改善、新サービスの開発、アルゴリズムの開発等に役立てるため</li>
                                            <li>キャンペーン、アンケート、モニター及び取材等の実施のため</li>
                                            <li>本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認及び回答のため</li>
                                            <li>本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</li>
                                            <li>本サービスに関する規約等の変更などを通知するため</li>
                                            <li>外部サービスのポイントの発行、計算、利用等ポイントプログラムの円滑な運営のため</li>
                                            <li>本サービスに関連して、個人を識別できない形式に加工した統計データを作成するため</li>
                                            <li>本サービスにより取得した個人情報の分析結果等を本人の同意を得て第三者提供を行うため</li>
                                            <li>その他、上記利用目的に付随する目的のため</li>
                                        </ol>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>3. 個人情報の収集方法等</h2>
                                        <p>3.1 当社は、本サービスの提供にあたり、本サービスのユーザーから、以下の情報を、以下の方法により収集します。</p>
                                        <ol>
                                            <li>収集する情報<br />氏名、住所、電話番号、メールアドレス、行動履歴</li>
                                            <li>収集方法
                                                <br />①	本サービス上でユーザーが入力する方法
                                                <br />②	本サービスをユーザーが利用した際に収集する方法
                                                <br />③	当社の提携先（情報提供元、広告主、広告配信先などを含みます。）から提供を受ける方法
                                            </li>
                                        </ol>
                                        <p>3.2 当社は、本サービスの提供期間中、及び本サービスの提供終了も第3.1項（1）に定める情報を保持します。但し、当該情報を利用する必要がなくなったときは、当該情報を遅滞なく消去するよう努めます。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>4. 個人情報利用目的の変更</h2>
                                        <p>当社は、個人情報の利用目的を関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には個人情報の主体である個人（以下「本人」といいます。）に通知し又は公表します。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>5. 個人情報の利用</h2>
                                        <p>5.1 当社は、個人情報保護法その他の法令により許容される場合を除き、本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。但し、次の場合はこの限りではありません。
                                        </p>
                                        <ol>
                                            <li>法令に基づく場合</li>
                                            <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                                            <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
                                            <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                                            <li>学術研究機関等に個人データを提供する場合であって、当該学術研究機関等が当該個人データを学術研究目的で取り扱う必要があるとき（当該個人データを取り扱う目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）。</li>
                                        </ol>
                                        <p>5.2 当社は、違法又は不当な行為を助長し、又は誘発するおそれがある方法により個人情報を利用しません。
                                        </p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>6. 個人情報の適正な取得</h2>
                                            <p>6.1 当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。
                                            </p>
                                            <p>6.2 当社は、次の場合を除き、あらかじめ本人の同意を得ないで、要配慮個人情報（個人情報保護法第2条第3項に定義されるものを意味します。）を取得しません。
                                            </p>
                                            <ol>
                                                <li>第5.1項第1号から第4号までのいずれかに該当する場合</li>
                                                <li>学術研究機関等から要配慮個人情報を取得する場合であって、当該要配慮個人情報を学術研究目的で取得する必要があるとき（当該要配慮個人情報を取得する目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）（当該個人情報取扱事業者と当該学術研究機関等が共同して学術研究を行う場合に限る。）</li>
                                                <li>当該要配慮個人情報が、本人、国の機関、地方公共団体、学術研究機関等、個人情報保護法第57条第1項各号に掲げる者その他個人情報保護委員会規則で定める者により公開されている場合</li>
                                                <li>本人を目視し、又は撮影することにより、その外形上明らかな要配慮個人情報を取得する場合</li>
                                                <li>第三者から要配慮個人情報の提供を受ける場合であって、当該第三者による当該提供が第9.1項各号のいずれかに該当するとき</li>
                                            </ol>
                                            <p>6.3 当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護委員会規則で定めるところにより、次に掲げる事項の確認を行います。ただし、当該第三者による当該個人情報の提供が第9.1項各号のいずれかに該当する場合又は第9.1項各号のいずれかに該当する場合を除きます。</p>
                                                <ol>
                                                <li>当該第三者の氏名又は名称及び住所、並びに法人の場合はその代表者（法人でない団体で代表者又は管理人の定めのあるものの場合は、その代表者又は管理人）の氏名</li>
                                                <li>当該第三者による当該個人情報の取得の経緯</li>
                                            </ol>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>7. 個人情報の安全管理</h2>
                                        <p>当社は、個人情報（当社が取得し、又は取得しようとしている個人情報を含む）の紛失、破壊、改ざん及び漏洩などのリスクに対して、個人情報の安全管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。当社の保有個人データ（当社が取得し、又は取得しようとしている個人情報であって、当社が保有個人データとして取り扱うことを予定しているものを含む）に関する具体的な安全管理措置の内容は、以下のとおりです。</p>
                                        <table className="t-privacy-table">
                                        <tr>
                                            <th>基本方針の策定</th>
                                            <td>個人データの適正な取扱いの確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針として、本プライバシーポリシーを策定</td>
                                        </tr>
                                        <tr>
                                            <th>個人データの取扱いに係る規律の整備</th>
                                            <td>取得、利用、保存、提供、削除・廃棄等の段階ごとに、取扱方法、責任者・担当者及びその任務等について個人データの取扱規程を策定</td>
                                        </tr>
                                        <tr>
                                            <th>組織的安全管理措置</th>
                                            <td>1）個人データの取扱いに関する責任者を設置するとともに、個人データを取り扱う従業者及び当該従業者が取り扱う個人データの範囲を明確化し、法や取扱規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備<br />
                                                2）個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施</td>
                                        </tr>
                                        <tr>
                                            <th>人的安全管理措置</th>
                                            <td>1）個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施<br />
                                                2）個人データについての秘密保持に関する事項を就業規則に記載</td>
                                        </tr>
                                        <tr>
                                            <th>物理的安全管理措置</th>
                                            <td>1）個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施<br />
                                                2）個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施</td>
                                        </tr>
                                        <tr>
                                            <th>技術的安全管理措置</th>
                                            <td>1）アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定<br />
                                                2）個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入</td>
                                        </tr>
                                        <tr>
                                            <th>外的環境の把握</th>
                                            <td>個人データを保管しているスウェーデン王国における個人情報の保護に関する制度を把握した上で安全管理措置を実施</td>
                                        </tr>
                                    </table>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>8. 漏洩時の報告等</h2>
                                        <p>当社は、当社の取り扱う個人情報（当社が取得し、又は取得しようとしている個人情報を含む）の漏洩、滅失、毀損等の事態が生じた場合において、個人情報保護法の定めに基づき個人情報保護委員会への報告及び本人への通知を要する場合には、かかる報告及び通知を行います。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>9. 第三者提供</h2>
                                            <p>9.1 当社は、第5.1項各号のいずれかに該当する場合を除くほか、あらかじめ本人の同意を得ないで、個人情報を第三者に提供しません。但し、次に掲げる場合は上記に定める第三者への提供には該当しません。</p>
                                            <ol>
                                                <li>利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って個人情報を提供する場合</li>
                                                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                                                <li>個人情報保護法の定めに基づき共同利用する場合</li>
                                            </ol>
                                            <p>9.2 当社は、個人情報を第三者に提供したときは、個人情報保護法第29条に従い、記録の作成及び保存を行います。</p>
                                            <p>9.3 当社は、第三者から個人情報の提供を受けるに際しては、個人情報保護法第30条に従い、必要な確認を行い、当該確認にかかる記録の作成及び保存を行うものとします。</p>
                                            <p>9.4 当社は、本人から本プライバシーポリシーへの同意を取得する方法その他のあらかじめ本人の同意を得る方法により、利用目的に従って、本サービスにより取得した個人情報の分析結果等を第三者（本サービスの提供に必要なサービスその他の役務を当社に提供する事業者を含みますが、これに限られません。）に提供することができるものとします。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>10. 個人情報等の開示</h2>
                                            <p>10.1 当社は、本人から、個人情報保護法の定めに基づき個人情報の開示を求められたときは、本人ご自身からのご請求であることを確認の上で、本人に対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。</p>
                                            <p>10.2 前項の定めは、本人が識別される個人情報にかかる、第9.2項に基づき作成した第三者への提供にかかる記録及び第9.3項に基づき作成した第三者からの提供にかかる記録について準用するものとします。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>11. 個人情報の訂正等</h2>
                                        <p>当社は、本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づきその内容の訂正、追加又は削除（以下「訂正等」といいます。）を求められた場合には、本人ご自身からのご請求であることを確認の上で、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨を本人に通知します（訂正等を行わない旨の決定をしたときは、本人に対しその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、この限りではありません。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>12. 個人情報の利用停止等</h2>
                                        <p>当社は、本人から、(1)本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われている、若しくは違法若しくは不当な行為を助長し、若しくは誘発するおそれがある方法により利用されているという理由により、又は本人の個人情報が偽りその他不正の手段により取得されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合、(2)個人情報がご本人の同意なく第三者に提供されているという理由により、個人情報保護法の定めに基づきその提供の停止（以下「提供停止」といいます。）を求められた場合、又は(3)当社が本人の個人情報を利用する必要がなくなった場合、本人の個人情報にかかる個人情報保護法第26条第1項本文に規定する事態が生じた場合その他本人の個人情報の取扱により本人の権利又は正当な利益が害されるおそれがある場合に該当するという理由により、個人情報保護法の定めに基づきその利用停止等又は提供停止を求められた場合において、そのご請求に理由があることが判明した場合には、本人ご自身からのご請求であることを確認の上で、遅滞なく個人情報の利用停止等又は提供停止を行い、その旨を本人に通知します。但し、個人情報保護法その他の法令により、当社が利用停止等又は提供停止の義務を負わない場合は、この限りではありません。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>13. Cookie（クッキー）その他の技術の利用</h2>
                                        <p>13.1 本サービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当社による本サービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、本サービスの一部の機能をご利用いただけなくなる場合があります。</p>
                                        <p>13.2 当社は、Googleアナリティクスを使用することがあります。Googleアナリティクスはデータの収集のためにCookie及びこれに類する技術を利用することがあります。当該データの収集は個人情報として使用又は認識できる情報ではありません。Googleアナリティクスで当該データが収集、処理される仕組みにつきましては、<a href="https://policies.google.com/technologies/partner-sites?hl=ja">こちら</a>をご確認下さい。</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>14. お問い合わせ</h2>
                                        <p>開示等のお申出、ご意見、ご質問、苦情のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願い致します。<br />個人情報取扱事業者の名称、住所及び代表者氏名<br />〒150-0043<br />東京都渋谷区道玄坂一丁目19番9号 第一暁ビル6階<br />株式会社DATAFLUCT（代表取締役CEO 久米村 隼人）<br />問い合わせ窓口<br />Email:info@datafluct.com</p>
                                    </div>
                                    <div className="t-privacy-wrap">
                                        <h2>15. 継続的改善</h2>
                                        <p>当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本プライバシーポリシーを変更することがあります。</p>
                                    </div>
                                    <div className="t-privacy-outer">
                                        <p>2019年10月9日制定<br />2022年6月9日前回改定<br />2024年3月22日最終改定</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                </div>
            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="プライバシーポリシー"
            description=""
            pathname=""
        />
    )
}
